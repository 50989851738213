export const arrayToTree = (list = [], fieldNames = {}) => {
  const {
    id = 'id',
    parentId = 'parentId',
    children = 'children'
  } = fieldNames;
  // 存储根节点
  const rootTree = [];
  const treeMap = new Map(list.map(node => [node[id], node]));
  const cloneList = [...list];
  while (cloneList.length) {
    const treeNode = cloneList.shift();
    // 根节点
    if (!treeMap.has(treeNode[parentId])) {
      rootTree.push(treeNode);
      continue;
    }
    const parentNode = treeMap.get(treeNode[parentId]);
    if (!Array.isArray(parentNode[children])) {
      parentNode[children] = [];
    }
    parentNode[children].push(treeNode);
  }
  treeMap.clear();
  return rootTree;
};