export const tableColumns = [
    {
        title: '',
        align: 'center',
        type: 'selection',
        width: 60,
        visible: true,
    },
    {
        title: '序号',
        align: 'center',
        type: 'index',
        slots: { customRender: 'indexNumber' },
        width: 60,
        visible: true,
    },
    {
        title: '设备名称',
        align: 'center',
        width: 140,
        key: 'name',
        visible: true,
    },
    {
        title: '设备SN号',
        align: 'center',
        width: 160,
        key: 'sn',
        visible: true,
    },
    {
        title: 'IMEI号',
        align: 'center',
        width: 160,
        key: 'imei',
        visible: true,
    },
    {
        title: '在线状态',
        align: 'center',
        width: 120,
        key: 'onlineState',
        slots: { customRender: 'onlineState' },
        visible: true,
    },
    {
        title: '激活时间',
        align: 'center',
        width: 160,
        key: 'activationTime',
        visible: true,
    },
    {
        title: '绑定时间',
        align: 'center',
        width: 160,
        key: 'activationTime',
        visible: false,
    },
    {
        title: '软件版本',
        align: 'center',
        width: 180,
        key: 'firmwareVersion',
        visible: true,
    },
    {
        title: '电池电量',
        align: 'center',
        width: 120,
        key: 'bat',
        slots: { customRender: 'bat' },
        visible: false,
    },
    {
        title: '信号强度',
        align: 'center',
        width: 100,
        key: 'csq',
        slots: { customRender: 'csq' },
        visible: true,
    },
    {
        title: 'ICCID',
        align: 'center',
        width: 160,
        key: 'iccid',
        visible: true,
    },
    {
        title: '授权人数',
        align: 'center',
        width: 95,
        key: 'authNumber',
        visible: true,
    },
    {
        title: '管理人数',
        align: 'center',
        width: 95,
        key: 'adminNumber',
        visible: true,
    },
    {
        title: '开锁规则',
        align: 'center',
        slots: { customRender: 'lockRule' },
        width: 160,
        key: 'lockRule',
        visible: false,
    },
    {
        title: '创建时间',
        align: 'center',
        width: 150,
        key: 'createTime',
        visible: true,
    },
    {
        title: '操作',
        fixed: 'right',
        align: 'center',
        slots: { customRender: 'operate' },
        width: 140,
        key: 'imei',
        visible: true,
    },
];
